import React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@material-ui/core/Backdrop';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';

import acceptCallIcon from 'assets/accept_call.png';
import hangUpCallIcon from 'assets/hangup_call.png';
import CloseIcon from '@material-ui/icons/Close';

const Container = styled.div`
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  display: flex;
  height: 100%;
`;

const ImgButton = styled.img`
  cursor: pointer;
`;

const CallingStatusContainer = styled.div`
  text-align: center;
  color: #FFF;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  color: #FFF;

  & > div {
    margin: 0 100px;
    white-space: pre;
  }
`;

const CloseBtnContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  color: #FFF;
`;

const LinkContainer = styled.div`
  position: absolute;
  top: 30%;
  text-align: center;
  width: 100%;
  padding: 25px;

  & > a {
    color: #FFF;
  }
`;

const RtcChatMobile = ({
  rtcCallingInfo,
  rtcCallingStatus,
  decline,
  accept,
  cancel,
  videoCallUrl,
  close,
}) => {
  const { t } = useTranslation();

  const renderCallerName = () => {
    switch (rtcCallingStatus) {
      case 'waiting_for_response':
        return (
          <Trans
            i18nKey="call.waiting_response_mobile"
            values={{ name: rtcCallingInfo.name }}
            // eslint-disable-next-line jsx-a11y/heading-has-content
            components={{ b: <h2 /> }}
            defaults="<b>Waiting for response from</b> <b>%{name}</b>"
          />
        );
      case 'being_called':
        return (
          <Trans
            i18nKey="call.invites_you_mobile"
            values={{ name: rtcCallingInfo.name }}
            // eslint-disable-next-line jsx-a11y/heading-has-content
            components={{ b: <h2 /> }}
            defaults="<b>%{name}</b> <b>invites you to video call</b>"
          />
        );
      default:
        return <h2>{rtcCallingInfo.name}</h2>;
    }
  };

  return (
    <Backdrop
      style={{ zIndex: 10000, backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
      open={rtcCallingStatus !== 'available'}
    >
      <Container>
        {
          ['inviter_connecting', 'invitee_connecting'].includes(rtcCallingStatus) && (
            <CloseBtnContainer onClick={close}>
              <CloseIcon />
            </CloseBtnContainer>
          )
        }
        <CallingStatusContainer>
          {renderCallerName()}
        </CallingStatusContainer>
        {
          rtcCallingStatus === 'being_called' && (
            <FlexContainer>
              <div role="button">
                <ImgButton src={hangUpCallIcon} alt="Hang Up" onClick={decline} />
                <p>{t('call.hang_up', 'Hang Up')}</p>
              </div>
              <div role="button">
                <ImgButton src={acceptCallIcon} alt="Accept" onClick={accept} />
                <p>{t('call.accept', 'Accept')}</p>
              </div>
            </FlexContainer>
          )
        }
        {
          rtcCallingStatus === 'waiting_for_response' && (
            <FlexContainer>
              <div role="button">
                <ImgButton src={hangUpCallIcon} alt="Hang Up" onClick={cancel} />
                <p>{t('call.hang_up', 'Hang Up')}</p>
              </div>
            </FlexContainer>
          )
        }
        {
          ['inviter_connecting', 'invitee_connecting'].includes(rtcCallingStatus) && (
            <LinkContainer>
              <a href={videoCallUrl} target="_blank" rel="noopener noreferrer">{t('call.open_video_call')}</a>
            </LinkContainer>
          )
        }
      </Container>
    </Backdrop>
  );
};

RtcChatMobile.propTypes = {
  rtcCallingInfo: PropTypes.shape().isRequired,
  rtcCallingStatus: PropTypes.string.isRequired,
  decline: PropTypes.func.isRequired,
  accept: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  videoCallUrl: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
};

export default RtcChatMobile;
