import React from 'react';
import ReactPlayer from 'react-player';
import PlayButton from 'assets/play-button.png';

export interface BannerVideoProps {
  videoUrl: string;
  thumbnailUrl: string;
  autoPlay?: boolean;
}

const BannerVideo: React.FC<BannerVideoProps> = ({
  videoUrl,
  thumbnailUrl,
  autoPlay = false,
}: BannerVideoProps) => (
  <ReactPlayer
    light={autoPlay ? false : thumbnailUrl || 'light'}
    url={videoUrl}
    controls
    playIcon={<img src={PlayButton} alt="Play Button" />}
    width="100%"
    height="100%"
    config={{
      file: {
        attributes: {
          autoPlay,
          playsInline: true,
          loop: true,
          disablePictureInPicture: true,
          controlsList: 'nodownload',
        },
      },
    }}
    playsinline
    style={{
      zIndex: 100,
      backgroundColor: 'black',
      padding: '0 !important',
    }}
  />
);

export default BannerVideo;
