import React from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import { BRAND_COLOR } from 'appenv';

import { Link } from 'react-router-dom';
import { Webinar } from 'models/webinars';
import ShowMore from 'components/utils/ShowMore';
import WebinarRootContainer from './components/WebinarRootContainer';
import WatchNowButton from './components/WatchNowButton';
import SpeakerList from './SpeakerList';
import Routes from '../../Routes';
import { hexToRGB } from '../../utils/colorUtils';
import { BREAKPOINT } from './constants';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  & > * {
    width: 100%;
  }
`;

const TopTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TopRightActionButtonWrapper = styled.div`
  width: 182px;
  flex: 0 0 auto;

  @media (max-width: ${BREAKPOINT}px) {
    display: none;
  }
`;

const BottomActionButtonWrapper = styled.div`
  margin-top: 16px;
  display: none;

  @media (max-width: ${BREAKPOINT}px) {
    display: block;
  }
`;

const NameContainer = styled(Link)`
  display: inline-block;
  font-style: normal;
  font-weight: bold;
  overflow: hidden;
  color: ${BRAND_COLOR || '#0CA58B'};
  text-decoration: none;

  @media (min-width: ${BREAKPOINT}px) {
    font-size: 24px;
    line-height: 29px;
    max-height: 58px;
  }

  @media (max-width: ${BREAKPOINT}px) {
    font-size: 18px;
    line-height: 22px;
    max-height: 22px;
  }
`;

const WebinarDescriptionContainer = styled.div`
  display: inline-block;
  font-size: 16px;
  line-height: 22px;
  overflow: hidden;
  margin: 16px 0 0 0;

  & > *:first-child {
    margin-top: 0;
  }
`;

const locale = 'en';
const WebinarItem = ({
  webinar, webinar: { translations }, className,
}: { webinar: Webinar; className: string }) => {
  const translation = translations[locale];
  return (
    <WebinarRootContainer className={className}>
      <ContentContainer>
        <div>
          <TopTitleContainer>
            <div>
              <NameContainer to={Routes.webinarSession(webinar.id)}>
                {translation.title}
              </NameContainer>
            </div>
            <TopRightActionButtonWrapper>
              <WatchNowButton kind={webinar.kind} id={webinar.id} name={translation.title} url={Routes.webinarSession(webinar.id)} />
            </TopRightActionButtonWrapper>
          </TopTitleContainer>
          {
            translation.description && translation.description.trim().length > 0 && (
              <WebinarDescriptionContainer>
                <ShowMore maxHeight={150}>
                  {ReactHtmlParser(translation.description)}
                </ShowMore>
              </WebinarDescriptionContainer>
            )
          }
        </div>
        <div>
          <SpeakerList
            webinarId={webinar.id}
            speakers={webinar.speakers}
          />
          <BottomActionButtonWrapper>
            <WatchNowButton id={webinar.id} name={translation.title} url={Routes.webinarSession(webinar.id)} />
          </BottomActionButtonWrapper>
        </div>
      </ContentContainer>
    </WebinarRootContainer>
  );
};

export default WebinarItem;
