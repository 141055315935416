import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';

import isEmpty from 'lodash/isEmpty';
import { APP, RONGCLOUD_CHATROOM_PREFIX } from 'appenv';
import { UserSessionContext } from 'components/UserSession';
import isChatroomDisabled from 'components/chat/chat_window_711/isChatroomDisabled711';

import ChatConversationList711 from './ChatConversationList711';
import ChatWindow from '../ChatWindow';
import DesktopChatWindow711 from './DesktopChatWindow711';
import MobileChatWindow711 from './MobileChatWindow711';

const ChatWindowSwitch711 = ({
  status,
  joiningChat,
  sendMessage,
  resendMessage,
  getHistoryMsg,
  chatRoomTitle,
  hasMoreMsg,
  setChatTarget,
  isChatWindowShow,
  setIsChatWindowShow,
  setChatRoomTitle,
  showGroupMemberList,
  setShowGroupMemberList,
}) => {
  const isDesktop = useMediaQuery('(min-width: 960px)');
  const { targetId = '', type } = useSelector((state) => state.chatTarget);
  const [prevTarget, setPrevTarget] = useState({});
  const { userSession } = useContext(UserSessionContext);

  const handleBackToPreviousChatroom = () => {
    setShowGroupMemberList(false);
    if (!isEmpty(prevTarget)) {
      setChatTarget(prevTarget.id, prevTarget.type, prevTarget.title);
    } else {
      setChatRoomTitle('Chats');
      setChatTarget({});
    }
    setPrevTarget({});
  };

  const needChatRoomList = APP !== 'hkstp608';

  const handleAvatarOnclick = (from, isSelfSent, userId, userName) => {
    setShowGroupMemberList(false);
    if (!isSelfSent && userId && type === 'group' && needChatRoomList) {
      setPrevTarget({
        id: targetId,
        type,
        title: chatRoomTitle,
      });
      setChatTarget(userId, 'private', userName);
      if (from) {
        let trackId = targetId;
        if (targetId === `${RONGCLOUD_CHATROOM_PREFIX}_hall`) {
          trackId = 'Hall';
        } else if (targetId === `${RONGCLOUD_CHATROOM_PREFIX}_announcement`) {
          trackId = 'Announcement';
        } else {
          const result = targetId.split(`${RONGCLOUD_CHATROOM_PREFIX}_booth_`);
          const boothId = result[1];
          if (boothId) {
            trackId = boothId;
          }
        }
        window.trackingEvent('1on1Chatroom_Enter', from, `${trackId} - ${chatRoomTitle}`);
      }
    }
  };

  const renderChatWindow = () => (
    <ChatWindow
      status={status}
      joiningChat={joiningChat}
      sendMessage={sendMessage}
      resendMessage={resendMessage}
      getHistoryMsg={getHistoryMsg}
      hasMoreMsg={hasMoreMsg}
      setChatTarget={setChatTarget}
      handleAvatarOnclick={handleAvatarOnclick}
    />
  );

  const renderChatConversationList = () => (
    <ChatConversationList711 setChatTarget={setChatTarget} userRole={userSession?.role} />
  );

  if (isChatroomDisabled()) return null;

  return (
    <>
      {
        isDesktop && needChatRoomList ? (
          <DesktopChatWindow711
            userRole={userSession?.role}
            joiningChat={joiningChat}
            chatRoomTitle={chatRoomTitle}
            isChatWindowShow={isChatWindowShow}
            setIsChatWindowShow={setIsChatWindowShow}
            handleAvatarOnclick={handleAvatarOnclick}
            ChatWindowComponent={renderChatWindow()}
            ChatConversationListComponent={renderChatConversationList()}
            showGroupMemberList={showGroupMemberList}
            setShowGroupMemberList={setShowGroupMemberList}
          />
        ) : (
          <MobileChatWindow711
            userRole={userSession?.role}
            joiningChat={joiningChat}
            isChatWindowShow={isChatWindowShow}
            needChatRoomList={needChatRoomList}
            handleBackToPreviousChatroom={handleBackToPreviousChatroom}
            chatRoomTitle={chatRoomTitle}
            handleAvatarOnclick={handleAvatarOnclick}
            ChatWindowComponent={renderChatWindow()}
            ChatConversationListComponent={renderChatConversationList()}
            showGroupMemberList={showGroupMemberList}
            setShowGroupMemberList={setShowGroupMemberList}
          />
        )
      }
    </>
  );
};

ChatWindowSwitch711.propTypes = {
  joiningChat: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  chatRoomTitle: PropTypes.string.isRequired,
  sendMessage: PropTypes.func.isRequired,
  resendMessage: PropTypes.func.isRequired,
  getHistoryMsg: PropTypes.func.isRequired,
  hasMoreMsg: PropTypes.bool.isRequired,
  setChatTarget: PropTypes.func.isRequired,
  isChatWindowShow: PropTypes.bool.isRequired,
  setIsChatWindowShow: PropTypes.func.isRequired,
  setChatRoomTitle: PropTypes.func.isRequired,
  showGroupMemberList: PropTypes.bool.isRequired,
  setShowGroupMemberList: PropTypes.func.isRequired,
};

export default ChatWindowSwitch711;
