import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { ENABLED_FEATURES } from 'appenv';
import { selectLocale } from 'models/localization';
import ExhibitorSearchResultItem from './ExhibitorSearchResultItem';
import WebinarSearchResultItem from './WebinarSearchResultItem';
import ParticipantSearchResultItem from './ParticipantSearchResultItem';

const pickFromExhibitor = (exhibitor, locale) => {
  if (ENABLED_FEATURES.xtraCMS) {
    const target = exhibitor.appliedDesignCodename === '002'
      ? 'middle_banner'
      : 'top_banner';

    const logo = exhibitor.logo[locale]?.originalImageUrl
      || (exhibitor.designElements || [])
        .find((node) => node.name === target)
        ?.image?.[locale]?.originalImageUrl;

    return {
      name: exhibitor.name[locale],
      logo,
      description: '',
    };
  }

  const { name, description } = exhibitor.translations[locale] || {};
  const logoThumbs = exhibitor.logo?.data?.thumbnails;
  const bestLogoThumb = logoThumbs?.find((thumb) => thumb?.url?.endsWith('?key=directus-medium-contain'));
  return { name, description, logo: bestLogoThumb?.url };
};

const SearchResultList = ({ type, results }) => {
  const locale = useSelector(selectLocale);
  return (
    <div>
      {
        type === 'exhibitor' && results.map((exhibitor) => {
          const { name, logo, description } = pickFromExhibitor(exhibitor, locale);

          return (
            <ExhibitorSearchResultItem
              key={exhibitor.id}
              id={`${exhibitor.id}`}
              name={name}
              logo={logo}
              description={description}
            />
          );
        })
      }
      {
        type === 'webinar' && results.map(({
          id,
          kind,
          startAt,
          endAt,
          translations,
          timezone,
        }) => {
          const { title } = translations[locale] || {};

          return (
            <WebinarSearchResultItem
              key={id}
              type={kind}
              id={`${id}`}
              name={title}
              startAt={startAt}
              endAt={endAt}
              timezone={timezone}
            />
          );
        })
      }
      {
        type === 'participant' && results.map((participant) => {
          const {
            name,
            uuid,
            customAttendeeFields: {
              vexpo_chat_tag: userTag,
              vexpo_chat_user_description: userDescription,
            },
          } = participant;
          return (
            <ParticipantSearchResultItem
              key={uuid}
              uuid={uuid}
              name={name}
              userTag={userTag}
              userDescription={userDescription}
            />
          );
        })
      }
    </div>
  );
};

SearchResultList.propTypes = {
  type: PropTypes.oneOf([
    'exhibitor',
    'webinar',
    'participant',
  ]),
  results: PropTypes.arrayOf(PropTypes.shape()),
};

SearchResultList.defaultProps = {
  type: 'exhibitor',
  results: [],
};

export default SearchResultList;
