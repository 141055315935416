import axios from 'axios';
import { EVENT_TOKEN, FETCH_PRODUCT_URL } from 'appenv';
import Apps from 'apps';
import { envSwitch } from 'utils/envUtils';

const eventToken = envSwitch([
  [Apps.Jetro817, 'gbtyes6dGZHmhdKTiu4RYxyw'],
  [Apps.Reed902, 'P5fqMS24CCsFk8sZ4HhdJQCV'],
], EVENT_TOKEN);

const productCategoriesByEventToken = async () => {
  try {
    const categoriesResponse = await axios.get(`${FETCH_PRODUCT_URL}/categories?eventToken=${eventToken}`);
    return categoriesResponse.data;
  } catch (e) {
    return null;
  }
};

export default productCategoriesByEventToken;
