import React, {
  useState,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { BRAND_COLOR, EVENT_NAME } from 'appenv';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { saveAs } from 'file-saver';
import EmptyStateImg from 'assets/search-empty-state.svg';
import { UserSessionContext } from 'components/UserSession';
import { useSelector } from 'react-redux';
import { selectTotalBookmarkCount, selectAllBookmarks } from 'models/bookmarks';
import BoothFileRow from 'components/BoothFileRow';
import { BookmarksAttachmentRow } from '../../booth/AttachmentRow';
import DownloadAllButton from './components/DownloadAllButton';

const NoBookmarkedAttachmentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
`;

const EmptyStateContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const EmptyStateText = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #484848;
  padding: 24px;
`;

const NoBookmarkedAttachment = () => (
  <NoBookmarkedAttachmentContainer>
    <EmptyStateContainer>
      <div>
        <img src={EmptyStateImg} alt="Coming Soon" />
        <EmptyStateText>
          No content yet :(
        </EmptyStateText>
        Bookmarked files will appear here
      </div>
    </EmptyStateContainer>
  </NoBookmarkedAttachmentContainer>
);

const DownloadContainer = styled.div`
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
`;

const downloadBookmarkList = async (bookmarkList) => {
  const bookmarkFileStyle = `
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }

    .bookmark-link {
      font-family: "Lato", Arial, sans-serif;
    }

    h1 {
      margin-top: 1em;
      margin-bottom: 1em;
      text-align: center;
      font-family: "Lato", Arial, sans-serif;
    }
    
    img {
      margin: 0 auto;
    }
    
    #image-container {
      text-align: center;
    }
    
    a {
      background-color: ${BRAND_COLOR || '#8B383A'};
      padding: 16px 24px;
      width: calc(100% - 32px);
      vertical-align: middle;
      display: inline-block;
      border-radius: 8px;
      color: white;
      text-decoration: none;
      margin: 0 16px 16px;
    }
  `;
  const bookmarkTags = bookmarkList.map((bookmark) => {
    // eslint-disable-next-line camelcase
    const name = bookmark.file?.filename_download;
    const url = bookmark.file.data.full_url;
    return `<a class="bookmark-link" target="_blank" href="${url}">${name}</a>`;
  });
  const htmlFile = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <link href="https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap" rel="stylesheet">
      <meta charset="utf-8">
      <title>${EVENT_NAME} Bookmark List</title>
      <style>${bookmarkFileStyle}</style>
    </head>
    <body>
      <h1>${EVENT_NAME} Bookmark List</h1>
      ${bookmarkTags.join('<br />')}
    </body>
    </html>
  `;
  const htmlBlob = new Blob([htmlFile], { type: 'text/html;charset=utf-8' });
  saveAs(htmlBlob, 'bookmarks.html');
};

const BookmarkListContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: ${({ hasAttachment }) => (hasAttachment ? 'flex-start' : 'center')};
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`;

const BookmarkList = ({ bookmarkedAttachments, toggleBookmark }) => {
  const { userSession } = useContext(UserSessionContext);
  const bookmarkList = useMemo(() => Object.values(bookmarkedAttachments)
    .sort((it) => (!it.removable ? -1 : 0))
    .sort((a, b) => (a.sort - b.sort)), [bookmarkedAttachments]);
  const [bookmarkZipStatus, setBookmarkZipStatus] = useState(null);
  const newBookmarksTotal = useSelector(selectTotalBookmarkCount);
  const newBookmarks = useSelector(selectAllBookmarks);
  const generateBookmarkFile = useCallback(() => {
    window.trackingEvent('MainMenu_Bookmark', 'Bookmark_Download_Click', userSession.email);
    setBookmarkZipStatus('Generating bookmark list file.');
    downloadBookmarkList(bookmarkList);
    setBookmarkZipStatus(null);
  }, [userSession.email, bookmarkList]);

  const hasAttachment = !!(bookmarkList.length + newBookmarksTotal);
  return (
    <>
      {!!bookmarkList.length && (
        <DownloadContainer>
          <DownloadAllButton status={bookmarkZipStatus} onClick={generateBookmarkFile} />
        </DownloadContainer>
      )}
      <BookmarkListContainer hasAttachment={hasAttachment}>
        {hasAttachment ? (
          <>
            {
              bookmarkList
                .map((attachment) => (
                  <BookmarksAttachmentRow
                    key={attachment.id}
                    attachment={attachment}
                    onRemove={() => toggleBookmark(attachment)}
                  />
                ))
            }
            {
              newBookmarks
                .map((bookmark) => (
                  <BoothFileRow
                    key={bookmark.id}
                    bookmark={bookmark}
                  />
                ))
            }
          </>
        ) : <NoBookmarkedAttachment />}
      </BookmarkListContainer>
    </>
  );
};

BookmarkList.propTypes = {
  bookmarkedAttachments: PropTypes.shape().isRequired,
  toggleBookmark: PropTypes.func.isRequired,
};

export default BookmarkList;
