import {
  LocalizableString, LocalizableImage,
  LocalizableVideo, LocalizableFile, WithTypename,
} from '../common/types';

export enum DesignElementKind {
  Image = 'IMAGE',
  Richtext = 'RICHTEXT',
  Color = 'COLOR',
  Video = 'VIDEO',
  PDF = 'PDF',
}

export interface DesignElement {
  id: string;
  name: string;
  kind: DesignElementKind;
  color?: string;
  image?: LocalizableImage;
  video?: LocalizableVideo;
  contentRichtextHtml?: LocalizableString;
  thumbnailImage?: LocalizableImage;
  pdf?: LocalizableFile;
}

export type BoothContentElement = WithTypename<{
  id: string;
  contentRichtextHtml?: LocalizableString;
  image?: LocalizableImage;
  videoFile?: LocalizableVideo;
  file?: LocalizableFile;
  question?: LocalizableString;
  answer?: BoothContentElement;
}>;

export enum BoothFetchingState {
  Pending = 'PENDING',
  Fulfilled = 'FULFILLED',
  Rejected = 'REJECTED',
}

export enum BoothFetchedLevel {
  Preview = 'PREVIEW',
  Details = 'DETAILS',
}

export interface Booth {
  salesImg: any;
  id: string;
  appliedDesignCodename: string;
  appointmentBookingUrl: string;
  meetupEnabled: boolean;
  website: string;
  boothNumber: string;
  description: LocalizableString;
  name: LocalizableString;
  logo: LocalizableImage;
  tier: WithTypename<{ name: LocalizableString }>;
  designElements: DesignElement[];
  categories: { id: string; name: LocalizableString }[];
  rootContentElement: {
    tabs: {
      title: LocalizableString;
      body: BoothContentElement[];
    }[];
  };
  hasAttachment: boolean;
  attachmentTab: number;
  livestreams: any;
  videoCallUrl: string;

  $fetchedLevel: BoothFetchedLevel;
  $lastReceivedAt: string;
}
