/* eslint-disable no-underscore-dangle */
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { schema, normalize } from 'normalizr';
import { selectAvailableLocales } from 'models/localization';
import boothById from 'network/boothById';
import moment from 'moment';
import {
  boothsAdapter,
  selectBoothPageInfo, selectBoothById,
} from './selectors';
import { mapLocalizable } from '../common/utils';
import { fetchPreviewBoothsRequest } from './requests';
import { Booth, BoothFetchedLevel, BoothFetchingState } from './types';

const urlRegex = /<p>(.*)<\/p>/;

const boothEntity = new schema.Entity('booths', {}, {
  processStrategy(entity) {
    let livestreams: any;
    const tabs = [];
    entity?.rootContentElement?.tabs?.nodes
      .forEach((it, index) => {
        if (it.title.values[0].value === 'Live Stream(s)') {
          if (it.body.nodes[0].answer.contentRichtextHtml.values[0].value) {
            livestreams = [{
              index,
            }];
            tabs.push(it);
          }
        } else if (it.title.values[0].value === 'Video Call') {
          if (it.body.nodes[0].answer.contentRichtextHtml.values[0].value) {
            [, entity.videoCallUrl] = it.body.nodes[0].answer.contentRichtextHtml.values[0].value.match(urlRegex);
          }
        } else {
          tabs.push(it);
        }
      });
    if (entity?.rootContentElement?.tabs?.nodes) {
      entity.rootContentElement.tabs.nodes = tabs;
    }
    entity.livestreams = livestreams;

    let attachmentTab = -1;
    const hasAttachment = entity?.rootContentElement?.tabs?.nodes
      ?.some(({ body: { nodes } }, index) => (
        nodes.some((element) => {
          attachmentTab = index;
          return element.__typename === 'BoothFileContentElement';
        })
      ));
    entity.attachmentTab = attachmentTab;
    entity.hasAttachment = !!hasAttachment;

    return mapLocalizable(entity);
  },
});

export const fetchBoothById = createAsyncThunk(
  'booths/fetchBoothById',
  async (boothId: string, { getState }) => {
    try {
      const state = getState();
      const locales = selectAvailableLocales(state);
      const response = await boothById(boothId, locales);

      response.$fetchedLevel = BoothFetchedLevel.Details;
      response.$lastReceivedAt = new Date().toISOString();

      const { entities } = normalize<Booth>([response], [boothEntity]);
      return entities.booths || {};
    } catch (error) {
      console.error(error);
    }
    return {};
  },
  {
    condition(boothId: string, { getState }) {
      const state = getState() as any;
      const fetchingState = state.cms.booths.boothFetchingState[boothId];
      switch (fetchingState) {
        case BoothFetchingState.Rejected: return false;
        case BoothFetchingState.Pending: return false;
        case BoothFetchingState.Fulfilled: {
          const booth = selectBoothById(state, boothId);
          return moment(booth.$lastReceivedAt).diff(moment(), 'minutes') > 4.5;
        }
        default: return true;
      }
    },
  },
);

export const fetchPreviewBooths = createAsyncThunk(
  'booths/fetchPreviewBooths',
  async ({ boothCount, boothsCursor, continueFetching = false }: any = {}, { getState, dispatch }) => {
    try {
      const state = getState();
      const locales = selectAvailableLocales(state);
      const response = await fetchPreviewBoothsRequest({ locales, boothsCursor, boothCount });

      const { pageInfo } = response.booths;
      delete response.booths.pageInfo;
      if (continueFetching && pageInfo.hasNextPage) dispatch(fetchPreviewBooths({ boothsCursor: pageInfo.endCursor, continueFetching, boothCount }));

      response.booths.nodes = response.booths.nodes.map((node) => ({
        ...node,
        $fetchedLevel: BoothFetchedLevel.Preview,
        $lastReceivedAt: new Date().toISOString(),
      }));

      const { entities } = normalize<Booth>(response.booths.nodes, [boothEntity]);

      return {
        booths: entities.booths || {},
        pageInfo: {
          ...pageInfo,
          fetching: continueFetching && pageInfo.hasNextPage,
        },
      };
    } catch (error) {
      console.error(error);
    }
    return {};
  },
);

export const fetchMorePreviewBooths = createAsyncThunk(
  'booths/fetchMorePreviewBooths',
  async (_, { getState, dispatch }) => {
    const state = getState();
    const pageInfo = selectBoothPageInfo(state);
    return dispatch(fetchPreviewBooths({
      boothsCursor: pageInfo.endCursor,
    }));
  },
  {
    condition(_, { getState }) {
      const state = getState();
      const pageInfo = selectBoothPageInfo(state);
      return pageInfo.hasNextPage && !pageInfo.fetching;
    },
  },
);

export const boothsSlice = createSlice({
  name: 'booths',
  initialState: boothsAdapter.getInitialState({
    fetching: true,
    pageInfo: {
      hasNextPage: true,
      endCursor: null,
      fetching: false,
    },
    boothFetchingState: {},
  }),
  reducers: {
    setPageInfoFetching(state, action: PayloadAction<boolean>) {
      state.pageInfo.fetching = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPreviewBooths.pending, (state) => {
      state.pageInfo.fetching = true;
    });
    builder.addCase(fetchPreviewBooths.rejected, (state) => {
      state.pageInfo.fetching = false;
    });
    builder.addCase(fetchPreviewBooths.fulfilled, (state, action) => {
      if (Object.keys(action.payload).length === 0) {
        state.fetching = false;
        state.pageInfo.hasNextPage = false;
        state.pageInfo.fetching = false;
        return;
      }
      state.fetching = false;
      state.pageInfo = action.payload.pageInfo;
      const filtered = Object.fromEntries(
        Object.entries(action.payload.booths)
          .filter(([key]) => state.entities[key]?.$fetchedLevel !== BoothFetchedLevel.Details),
      );
      boothsAdapter.upsertMany(state, filtered);
    });

    builder.addCase(fetchBoothById.pending, (state, action) => {
      const boothId = action?.meta?.arg;
      state.boothFetchingState[boothId] = BoothFetchingState.Pending;
    });
    builder.addCase(fetchBoothById.rejected, (state, action) => {
      const boothId = action?.meta?.arg;
      state.boothFetchingState[boothId] = BoothFetchingState.Rejected;
    });
    builder.addCase(fetchBoothById.fulfilled, (state, action) => {
      const boothId = action?.meta?.arg;
      state.boothFetchingState[boothId] = BoothFetchingState.Fulfilled;
      boothsAdapter.upsertMany(state, action.payload);
    });
  },
});

export default boothsSlice.reducer;
