/* eslint-disable import/prefer-default-export */
import { apiUrl, eventToken } from 'network/common/cmsConfig';
import request from 'graphql-request';
import {
  PreviewBooth,
  CommonLocalizableString, CommonLocalizableImage,
  CommonLocalizableVideo, CommonLocalizableFile,
} from 'network/common/cmsFragment';

const fetchPreviewBoothsQuery = `
  ${CommonLocalizableString}
  ${CommonLocalizableImage}
  ${CommonLocalizableVideo}
  ${CommonLocalizableFile}
  ${PreviewBooth}

  query fetchPreviewBoothsQuery($eventToken: String!, $locales: [Locale!]!, $boothCount: Int, $boothsCursor: String) {
    data: eventByToken(eventToken: $eventToken) {
      booths(first: $boothCount, after: $boothsCursor) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          ...PreviewBooth
        }
      }
    }
  }
`;

export interface FetchPreviewBoothsRequestProps {
  locales?: string[];
  boothsCursor?: string;
  boothCount?: number;
}

export const fetchPreviewBoothsRequest = ({
  locales = ['en'],
  boothCount = 2,
  boothsCursor,
}: FetchPreviewBoothsRequestProps = {}) => request(
  apiUrl,
  fetchPreviewBoothsQuery,
  {
    eventToken,
    locales,
    boothCount,
    boothsCursor,
  },
).then((response) => response.data);
