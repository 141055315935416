import { createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { selectLocale } from 'models/localization';
import union from 'lodash/union';
import { Booth, BoothFetchingState } from './types';
import { selectBoothTaxonomies } from '../events';

export const boothsAdapter = createEntityAdapter<Booth>();

export const {
  selectIds: selectBoothIds,
  selectEntities: selectBoothEntities,
  selectAll: selectAllBooths,
  selectById: selectBoothById,
} = boothsAdapter.getSelectors((state: any) => state.cms.booths);

export const selectBoothPageInfo = createSelector(
  (state: any) => state.cms.booths,
  (booths) => booths.pageInfo,
);

export const selectAllValidBooths = createSelector(
  selectAllBooths,
  (booths) => booths.filter((it) => Object.values(it.name).some((name) => name)),
);

export const selectFetchingStateByBoothId = (boothId: string) => createSelector(
  (state: any) => state.cms.booths,
  (booths) => (booths.boothFetchingState[boothId] || BoothFetchingState.Pending) as BoothFetchingState,
);

export const selectBoothsByKeyword = (keyword: string, options: any = {}) => {
  const { locale } = options;
  const limit = options.limit || undefined;
  const lowerCaseKeyword = keyword.toLowerCase();
  return createSelector(
    [selectAllValidBooths, selectLocale],
    (booths, fallbackLocale) => (
      booths
        .filter((booth) => (
          lowerCaseKeyword && (
            (booth.name[locale] || booth.name[fallbackLocale])?.toLowerCase().includes(lowerCaseKeyword)
            || booth.categories.some((category) => (category.name[locale] || category.name[fallbackLocale])?.toLowerCase().includes(lowerCaseKeyword))
          )
        ))
        .slice(0, limit)
    ),
  );
};

export const filterBoothsByKeywords = (keyword: any) => {
  if (Object.keys(keyword).length === 0) return selectAllValidBooths;
  return createSelector(
    selectAllValidBooths,
    (booths) => {
      const options = Object.keys(keyword);
      return booths.filter((booth) => {
        const names = booth.categories
          .flatMap((category) => Object.values(category.name));
        return options.every((it) => names.includes(it));
      });
    },
  );
};

export const filterBoothsByBoothTaxonomiesKeyword = (keyword: any) => {
  if (Object.keys(keyword)?.length === 0) return selectAllValidBooths;
  return createSelector(
    [selectAllValidBooths, selectBoothTaxonomies, selectLocale],
    (booths, boothTaxonomies, locale) => {
      const boothTaxonomiesObj = boothTaxonomies
        .reduce((sum, it) => ({
          ...sum,
          [it.name[locale]]: (it.subcategories || []).map((sub) => sub.name[locale]),
        }), {});

      return booths
        .filter((booth) => {
          const categories = booth.categories.flatMap((category) => category.name[locale]);
          return Object
            .entries(keyword)
            .every(([key, value]: any) => value.some((it: any) => categories.includes(boothTaxonomiesObj[key]?.[it])));
        });
    },
  );
};

export const getFilterOptionByBooth = (fallbackLocale = 'en') => createSelector(
  [selectAllValidBooths, selectLocale],
  (booths, locale) => {
    const options: any = { count: {} };
    const categories = booths
      .flatMap((it) => it.categories.map((category) => category.name[locale] || category.name[fallbackLocale]))
      .filter((it) => it);

    union(categories).forEach((it) => {
      options[it] = [it];
      options.count[it] = 0;
    });

    categories.forEach((it) => {
      options.count[it] += 1;
    });

    return options;
  },
);

export const getFilterOptionByBoothTaxonomies = () => createSelector(
  [selectAllValidBooths, selectBoothTaxonomies, selectLocale],
  (booths, boothTaxonomies, locale) => {
    const options = boothTaxonomies.reduce((sum, next) => {
      const key = next.name[locale];
      if (!key) return sum;

      const values = (next.subcategories || [next])
        .map((subCategory) => subCategory.name[locale])
        .filter((it) => it);

      return ({
        ...sum,
        [key]: values,
      });
    }, { count: {} });

    const categories = booths
      .flatMap((booth) => booth.categories.map((category) => category.name[locale]))
      .filter((it) => it);

    categories.forEach((it) => {
      if (!options.count[it]) options.count[it] = 0;
      options.count[it] += 1;
    });

    return options;
  },
);
