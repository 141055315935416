import { FETCH_PRODUCT_URL } from 'appenv';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit';
import { eventToken } from 'network/common/cmsConfig';
import { selectLocale } from './localization';
import { Product, LocalizedProduct } from './types/products';
import getLocalizedProduct from './helpers/getLocalizedProduct';

const getEventProductList = async (startRange: number, endRange: number) => {
  const PRODUCT_URL = `${FETCH_PRODUCT_URL}/products?filter={"eventToken":"${eventToken}"}&sort=["id","ASC"]&range=[${startRange},${endRange}]`;
  const productResponse = await fetch(PRODUCT_URL);
  let productList = await productResponse.json();
  productList = productList.map((product) => {
    delete product.createdOn;
    delete product.lastModifiedOn;
    return product;
  });
  return productList;
};

const getEventProductListByCategories = async (categories: Record<string, string>, startRange: number, endRange: number) => {
  const PRODUCT_URL = `${FETCH_PRODUCT_URL}/products?filter={"eventToken":"${eventToken}","categories":${JSON.stringify(categories)}}&range=[${startRange},${endRange}]&sort=["id","ASC"]`;
  const productResponse = await fetch(PRODUCT_URL);
  const productList = await productResponse.json();
  return productList;
};

const productsAdapter = createEntityAdapter<Product>();

export const {
  selectAll: selectAllProducts,
} = productsAdapter.getSelectors((state: any) => state.products);

export const selectAllProductsByLocale = createSelector(
  [selectAllProducts, selectLocale],
  (products: Product[], locale: string): LocalizedProduct[] => products
    .map((product) => getLocalizedProduct(product, locale)),
);

export const selectAllProductsByBoothId = (boothId: string) => createSelector(
  [selectAllProductsByLocale],
  (products: LocalizedProduct[]): LocalizedProduct[] => (products || [])
    .filter((product: LocalizedProduct) => product.boothId === boothId),
);

const FETCH_PRODUCT_INCREMENT = 30;
const fetchProductAction = async (
  [startRange, endRange]: [number, number] = [0, FETCH_PRODUCT_INCREMENT],
) => {
  try {
    const result = await getEventProductList(startRange, endRange - 1);
    return result;
  } catch (error) {
    console.error(error);
  }
  return {};
};

export const fetchProducts = createAsyncThunk(
  'products/fetchAll',
  fetchProductAction,
);

const fetchProductsByCategoryAction = async ({
  categories,
  range = [0, FETCH_PRODUCT_INCREMENT - 1],
}: {
  categories: Record<string, any>;
  range?: [number, number];
}) => {
  try {
    const [startRange, endRange] = range;
    const result = await getEventProductListByCategories(categories, startRange, endRange);
    return result;
  } catch (error) {
    console.error(error);
  }
  return {};
};

export const fetchProductsNextPage = createAsyncThunk(
  'products/fetchNextPage',
  async (_, { getState }) => {
    const { products, productCategories } = (getState() as any);
    const { page }: {
      page: number;
    } = products.pageInfo as any;
    const selectedCategoryIds = productCategories.selected.ids;
    const selectedCategoryIdsMap = selectedCategoryIds.reduce((acc, categoryId: string) => {
      acc[categoryId] = true;
      return acc;
    }, {});
    const isCategorySelected = !!selectedCategoryIds.length;
    try {
      const result = isCategorySelected
        ? await fetchProductsByCategoryAction({
          categories: selectedCategoryIdsMap,
          range: [page * FETCH_PRODUCT_INCREMENT, (page + 1) * FETCH_PRODUCT_INCREMENT],
        })
        : await fetchProductAction([page * FETCH_PRODUCT_INCREMENT, (page + 1) * FETCH_PRODUCT_INCREMENT]);
      return result;
    } catch (e) {
      console.error(e);
      return null;
    }
  },
);

export const fetchProductsByCategory = createAsyncThunk(
  'products/fetchByCategory',
  fetchProductsByCategoryAction,
);

const INITIAL_PRODUCTS_PAGEINFO_STATE = {
  page: 0,
  hasNextPage: true,
};

export const productSlice = createSlice({
  name: 'products',
  initialState: productsAdapter.getInitialState({
    fetching: true,
    pageInfo: INITIAL_PRODUCTS_PAGEINFO_STATE,
    productFetchingState: {},
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.pending, (state: any) => {
      productsAdapter.removeAll(state);
      state.fetching = true;
      state.pageInfo = INITIAL_PRODUCTS_PAGEINFO_STATE;
    });
    builder.addCase(fetchProductsByCategory.pending, (state: any) => {
      productsAdapter.removeAll(state);
      state.fetching = true;
      state.pageInfo = INITIAL_PRODUCTS_PAGEINFO_STATE;
    });
    builder.addCase(fetchProductsByCategory.fulfilled, (state: any, action) => {
      state.fetching = false;
      productsAdapter.upsertMany(state, action.payload);
      state.pageInfo.hasNextPage = !!(action.payload?.length);
      if (action.payload?.length) {
        state.pageInfo.page += 1;
      }
    });
    builder.addCase(fetchProducts.fulfilled, (state: any, action) => {
      productsAdapter.upsertMany(state, action.payload);
      state.fetching = false;
      state.pageInfo.hasNextPage = !!(action.payload?.length);
      if (action.payload?.length) {
        state.pageInfo.page += 1;
      }
    });
    builder.addCase(fetchProducts.rejected, (state: any) => {
      state.fetching = false;
    });
    builder.addCase(fetchProductsNextPage.pending, (state: any) => {
      state.fetching = true;
    });
    builder.addCase(fetchProductsNextPage.fulfilled, (state: any, action) => {
      productsAdapter.upsertMany(state, action.payload);
      state.fetching = false;
      state.pageInfo.hasNextPage = !!(action.payload?.length);
      if (action.payload?.length) {
        state.pageInfo.page += 1;
      }
    });
    builder.addCase(fetchProductsNextPage.rejected, (state: any) => {
      state.fetching = false;
    });
  },
});

export default productSlice.reducer;
