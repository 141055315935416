import React, {
  useContext, useCallback, useMemo, useEffect,
} from 'react';
import styled from 'styled-components';
import { RONGCLOUD_CHATROOM_PREFIX, ENABLED_FEATURES, ENV } from 'appenv';
import BuildingIcon from 'components/icons/Building';
import ChatroomIcon from 'components/icons/Chatroom';
import ChatContext from 'components/chat/ChatContext';
import Routes from 'Routes';
import { selectLocale } from 'models/localization';
import { LocalizedProduct } from 'models/types/products';
import { useSelector, useDispatch } from 'react-redux';
import { fetchBoothById } from 'models/cms/booths';
import CompanyLink, { CompanyRouterLink } from 'components/product/CompanyLink';
import CompanyName from 'components/product/CompanyName';
import InsertInvitationIcon from 'components/icons/Calendar';
import { useTranslation } from 'react-i18next';
import findAvailableLocale from 'models/helpers/findAvailableLocale';
import isChatroomDisabledByReed from 'components/chat/utils/isChatroomDisabledByReed';
import ProductImageSlider from './ProductImageSlider';

const RootContainer = styled.div`
  display: flex;
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  padding-top: 16px;

  @media (max-width: 959px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ProductSummary = styled.div`
  display: inline-block;
  flex-grow: 1;
  padding-left: 16px;

  @media (max-width: 959px) {
    padding-top: 16px;
  }
`;

const ProductName = styled.span`
  font-size: 24px;
  font-weight: bold;
`;

const ProductImageContainer = styled.div<{ hasDots: boolean }>`
  width: 100%;
  display: inline-block;
  margin-bottom: ${({ hasDots }) => (hasDots ? '70px' : 0)};
  max-width: 400px;
`;

const ChatLink = styled(CompanyLink)`
  & svg {
    height: 24px;
  }
`;

const ProductInfo = styled.div`
  padding-top: 24px;
`;

const ProductHighlight = ({
  product: {
    name,
    images,
    booth,
    boothId,
  },
}: {
  product: LocalizedProduct;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const locale = useSelector(selectLocale);
  const { meetupEnabled = false, appointmentBookingUrl = '', name: boothName = {} } = booth || {};
  const localizedBoothName = useMemo(() => findAvailableLocale(boothName, locale), [locale, boothName]);

  const { showChatWindow } = useContext(ChatContext);
  const openChatWindow = useCallback(() => {
    const chatroomID = ENABLED_FEATURES.xtraCMS ? `${ENV}_${boothId}` : `${RONGCLOUD_CHATROOM_PREFIX}_booth_${boothId}`;
    showChatWindow(chatroomID, 'group', localizedBoothName);
  }, [boothId, showChatWindow, localizedBoothName]);

  const hideChatLink = useMemo(() => isChatroomDisabledByReed(), []);

  useEffect(() => {
    dispatch(fetchBoothById(boothId));
  }, [boothId, dispatch]);

  return (
    <RootContainer>
      <ProductImageContainer hasDots={images?.length > 1}>
        <ProductImageSlider
          images={images}
          rsProps={{
            dots: images?.length > 1,
          }}
        />
      </ProductImageContainer>
      <ProductSummary>
        <ProductName>
          {name}
        </ProductName>
        <ProductInfo>
          {localizedBoothName && (
            <CompanyRouterLink to={Routes.booth(boothId)}>
              <BuildingIcon />
              <CompanyName>{localizedBoothName}</CompanyName>
            </CompanyRouterLink>
          )}
          {!hideChatLink && (
            <ChatLink href="#" onClick={openChatWindow}>
              <ChatroomIcon />
              <CompanyName>{t('product.chat_now', 'Chat Now')}</CompanyName>
            </ChatLink>
          )}
          {!!meetupEnabled && !!appointmentBookingUrl && (
            <ChatLink href={appointmentBookingUrl}>
              <InsertInvitationIcon />
              <CompanyName>{t('booth.meetup', 'Meetup')}</CompanyName>
            </ChatLink>
          )}
        </ProductInfo>
      </ProductSummary>
    </RootContainer>
  );
};

export default ProductHighlight;
