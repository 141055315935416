import React from 'react';
import { Booth } from 'models/cms/booths';
import BoothTemplate01 from './BoothTemplate01';
import BoothTemplate02 from './BoothTemplate02';
import BoothTemplate03 from './BoothTemplate03';

export interface BoothCMSTemplateProps {
  booth: Booth;
  handleClick: () => any;
}

const BoothCMSTemplate: React.FC<BoothCMSTemplateProps> = (props: BoothCMSTemplateProps) => {
  const { booth } = props;

  switch (booth?.appliedDesignCodename) {
    case '003':
      return <BoothTemplate03 {...props} />;
    case '002':
      return <BoothTemplate02 {...props} />;
    case '001':
    default:
      return <BoothTemplate01 {...props} />;
  }
};

export default BoothCMSTemplate;
