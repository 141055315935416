import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { APP_ENV } from 'appenv';
import { pickBy } from 'lodash';
import { useSelector } from 'react-redux';
import { selectAllDefaultBookmarks } from 'models/defaultBookmarks';
import { CMSFile } from 'models/cmsFile';

const BOOKMARK_STORAGE_KEY = `${APP_ENV}_bookmarked-attachments`;
const BookmarksContext = React.createContext<{[id: string]: any}>({});

const BookmarksContextStateProvider = ({ children }) => {
  const [bookmarkedAttachments, setBookmarkedAttachments]: [Record<any, any>, React.Dispatch<React.SetStateAction<any>>] = useState(JSON.parse(localStorage.getItem(BOOKMARK_STORAGE_KEY)) || {});
  const defaultBookmarks: any[] = useSelector(selectAllDefaultBookmarks);
  const [isLoadDefaultBookmarks, setIsLoadDefaultBookmarks] = useState(false);

  interface BookmarkAttachment {
    id: string | number;
    file: CMSFile;
    removable: boolean;
    sort?: number;
    default?: boolean;
  }

  useEffect(() => {
    if ([...defaultBookmarks].length < 1) return;
    const updated = {};
    defaultBookmarks.forEach((bookmark) => {
      const attachment: BookmarkAttachment = {
        id: `default-${bookmark.attachment.id}`,
        file: { ...bookmark.attachment },
        removable: bookmark.removable,
        sort: bookmark.sort,
        default: true,
      };
      updated[attachment.id] = attachment;
    });
    setBookmarkedAttachments((old) => {
      const userBookmarks = pickBy(old, (val) => !val.default);
      const newBookmarkedAttachments = { ...updated, ...userBookmarks };
      localStorage.setItem(BOOKMARK_STORAGE_KEY, JSON.stringify(newBookmarkedAttachments));
      return newBookmarkedAttachments;
    });
  }, [defaultBookmarks, isLoadDefaultBookmarks]);

  const toggleBookmark = (attachment) => {
    if (bookmarkedAttachments[attachment.id] !== undefined) {
      delete bookmarkedAttachments[attachment.id];
    } else {
      bookmarkedAttachments[attachment.id] = { ...attachment, removable: true };
    }
    localStorage.setItem(BOOKMARK_STORAGE_KEY, JSON.stringify(bookmarkedAttachments));
    setBookmarkedAttachments({ ...bookmarkedAttachments });
  };

  const loadDefaultBookmarks = () => setTimeout(() => !isLoadDefaultBookmarks && setIsLoadDefaultBookmarks(true));

  return (
    <BookmarksContext.Provider
      value={{
        bookmarkedAttachments,
        setBookmarkedAttachments,
        toggleBookmark,
        loadDefaultBookmarks,
      }}
    >
      {children}
    </BookmarksContext.Provider>
  );
};

BookmarksContextStateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export {
  BookmarksContext,
  BookmarksContextStateProvider,
};
