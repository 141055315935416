import React, {
  useContext,
  useMemo,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { NavLink, matchPath } from 'react-router-dom';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import BookmarkIcon from '@material-ui/icons/BookmarkBorder';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import Routes from 'Routes';
import {
  APP, PAGE_NAMES, BRAND_COLOR, ENABLED_FEATURES, SUB_HALL,
  RONGCLOUD_CHATROOM_PREFIX,
} from 'appenv';
import Apps, { reedApps } from 'apps';
import { envSwitch } from 'utils/envUtils';
import ChatContext from 'components/chat/ChatContext';
import { UserSessionContext } from 'components/UserSession';
import { BookmarksContext } from 'components/modals/bookmarks/Bookmarks';
import BookmarksModal from 'components/modals/bookmarks/BookmarksModal';
import UserAvatar from 'components/UserAvatar';
import IconLogout from 'components/icons/Logout';
import { useSelector } from 'react-redux';
import { selectTotalBookmarkCount } from 'models/bookmarks';

const StyledDrawer = withStyles(() => ({
  paper: {
    width: '240px',
  },
}))(Drawer);

const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 53px 0 16px;
  flex-shrink: 0;
`;

const UserName = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #484848;
  margin-top: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledListContainer = styled.div`
  flex: 1 0 auto;
  padding-top: ${({ hideUserSession }) => (hideUserSession ? 28 : 0)}px;
`;

const StyledListItem = withStyles(() => ({
  gutters: {
    padding: '8px 24px',
  },
}))(ListItem);

const StyledSecondaryList = withStyles(() => ({
  root: {
    paddingBottom: 0,
  },
}))(List);

const StyledSecondaryListItem = withStyles(() => ({
  gutters: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '&:last-child': {
      paddingBottom: 0,
    },
  },
}))(ListItem);

const StyledNavLink = styled(NavLink)`
  color: #484848;
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  word-break: break-word;
`;

const StyledPublicChatBtn = styled.div`
  color: #484848;
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  word-break: break-word;
  cursor: ${({ isRongCloudInitialized }) => (isRongCloudInitialized ? 'pointer' : 'wait')};
`;

const StyledSecondaryNavTitle = styled.div`
  color:
    ${({ activeStyle, isActive }) => (
    activeStyle?.color && isActive && isActive(null, window.location)
      ? activeStyle.color
      : '#484848'
  )};
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  word-break: keep-all;
`;

const StyledSecondaryNavLink = styled(StyledNavLink)`
  font-weight: unset;
  line-height: 20px;
  line-height: 18px;
  word-break: break-all;
`;

const StyledNavButtonText = styled.span`
  color: #484848;
  text-decoration: none;
  margin-left: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
`;

const LogoutButtonContainer = styled.div`
  flex: 0 0 auto;
  padding: 24px;
`;

const StyledLogoutBtn = styled.div`
  color: #484848;
  text-decoration: none;
  display: flex;
  align-items: center;
`;

const LogoutText = styled.span`
  margin-left: 12px;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
`;

const activeLinkStyle = {
  color: BRAND_COLOR || '#0CA58B',
};

const TranslatedName = styled.div`
  margin-top: 5px;
`;

const nonEngRegex = new RegExp(/([A-Za-z0-9$&+,:;=?@#|'<>.^*()%!-\\ ]*)(.*)/);
const getTranslatedName = (name) => (envSwitch([
  ['sph706', () => {
    if (!name) return [name, null];
    const matched = name.match(nonEngRegex);
    return [matched[1].trim(), matched[2].trim()];
  }],
], () => [name, null])());

const DrawerView = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const totalBookmarks = useSelector(selectTotalBookmarkCount);
  const { userSession, setUserSession, canSee } = useContext(UserSessionContext);
  const [bookmarksModalOpen, setBookmarkOpen] = useState(false);
  const { showChatWindow } = useContext(ChatContext);

  const openBookmarks = useCallback(() => {
    onClose();
    setBookmarkOpen(true);
  }, [setBookmarkOpen, onClose]);

  const closeBookmarks = useCallback(() => {
    setBookmarkOpen(false);
  }, [setBookmarkOpen]);

  const onLogoutClick = () => {
    setUserSession({});
  };
  const hideUserSession = useMemo(() => (APP === 'ms624'), []);
  const isRongCloudInitialized = window?.RongIMClient?._instance;
  const handleClick = (key) => {
    window.trackingEvent('Event Navigation', 'MainMenu_Click', key);

    if (key === 'Networking Lounge' && isRongCloudInitialized) {
      showChatWindow(`${RONGCLOUD_CHATROOM_PREFIX}_public_chat`, 'group', 'Networking Lounge', true);
    }
  };

  const [lobbyName, lobbyTranslatedName] = useMemo(() => getTranslatedName(PAGE_NAMES.lobby || t('page.lobby', 'Lobby')), [t]);
  const [hallName, hallTranslatedName] = useMemo(() => getTranslatedName(PAGE_NAMES.hall || t('page.hall', 'Exhibition Hall')), [t]);
  const [webinarName, webinarTranslatedName] = useMemo(() => getTranslatedName(PAGE_NAMES.webinar || t('page.webinar', 'Webinars')), [t]);
  const [participantName, participantTranslatedName] = useMemo(() => {
    if (APP === Apps.Eventage824) return [PAGE_NAMES.participant, null];
    return getTranslatedName(t('page.participants', PAGE_NAMES.participant));
  }, [t]);
  const [newsName, newsTranslatedName] = useMemo(() => getTranslatedName(PAGE_NAMES.news), []);
  const [customPageName] = useMemo(() => getTranslatedName(PAGE_NAMES.custom || t('page.custom', 'Custom Page')), [t]);

  const hallLink = useMemo(() => {
    switch (APP) {
      case 'sph706': {
        return (
          <div>
            <StyledSecondaryNavTitle
              activeStyle={activeLinkStyle}
              isActive={(match, location) => (
                matchPath(location.pathname, {
                  path: [Routes.hall, Routes.booth()],
                  exact: true,
                })
              )}
            >
              <div>{hallName}</div>
              {hallTranslatedName && <TranslatedName>{hallTranslatedName}</TranslatedName>}
            </StyledSecondaryNavTitle>
            <StyledSecondaryList>
              {SUB_HALL.map(({ name, link }) => {
                const [enName, translatedName] = getTranslatedName(name);
                return (
                  <StyledSecondaryListItem key={name}>
                    <StyledSecondaryNavLink
                      onClick={() => handleClick('Hall')}
                      to={link}
                    >
                      <div>{enName}</div>
                      {translatedName && <TranslatedName>{translatedName}</TranslatedName>}
                    </StyledSecondaryNavLink>
                  </StyledSecondaryListItem>
                );
              })}
            </StyledSecondaryList>
          </div>
        );
      }
      default:
        return (
          <StyledNavLink
            onClick={() => handleClick('Hall')}
            to={Routes.hall}
            activeStyle={activeLinkStyle}
            isActive={(match, location) => (
              matchPath(location.pathname, {
                path: [Routes.hall, Routes.booth()],
                exact: true,
              })
            )}
          >
            <div>{hallName}</div>
            {hallTranslatedName && <TranslatedName>{hallTranslatedName}</TranslatedName>}
          </StyledNavLink>
        );
    }
  }, [hallName, hallTranslatedName]);

  const webinarLink = useMemo(() => {
    switch (APP) {
      case 'ms624': return (
        <StyledNavLink to="/about" onClick={() => handleClick('Webinar')} activeStyle={activeLinkStyle}>
          {PAGE_NAMES.webinar}
        </StyledNavLink>
      );
      default: return (
        <StyledNavLink to={Routes.webinar} onClick={() => handleClick('Webinar')} activeStyle={activeLinkStyle}>
          <div>{webinarName}</div>
          {webinarTranslatedName && <TranslatedName>{webinarTranslatedName}</TranslatedName>}
        </StyledNavLink>
      );
    }
  }, [webinarName, webinarTranslatedName]);

  const newsLink = useMemo(() => {
    switch (APP) {
      case Apps.SPH706: return (
        <StyledNavLink to="/" target="_blank" rel="noopener noreferrer">
          <div>{newsName}</div>
          {newsTranslatedName && <TranslatedName>{newsTranslatedName}</TranslatedName>}
        </StyledNavLink>
      );
      default: return null;
    }
  }, [newsName, newsTranslatedName]);

  const NavBarMiddleSpacer = styled.div`
    height: 80px;
    width: 1px;
  `;

  const userFullName = userSession?.fullName || '';

  useEffect(() => {
    const { $zopim } = window;
    if ($zopim) {
      $zopim(() => {
        $zopim.livechat.setName(userFullName);
      });
    }
  }, [userFullName]);

  const onHelpBtnClick = useCallback(() => {
    onClose();
    const { $zopim } = window;
    $zopim(() => {
      $zopim.livechat.setName(userFullName);
      $zopim.livechat.window.show();
    });
  }, [userFullName, onClose]);

  const sortedLinks = [
    {
      element: (
        <StyledListItem key="custom">
          <StyledNavLink
            onClick={() => handleClick('Custom Page')}
            to={Routes.custom}
            activeStyle={activeLinkStyle}
            isActive={(match, location) => (
              matchPath(location.pathname, {
                path: [Routes.custom],
                exact: true,
              })
            )}
          >
            <div>{customPageName}</div>
          </StyledNavLink>
        </StyledListItem>
      ),
      show: canSee('lobby') && ENABLED_FEATURES.customPage,
      sort: envSwitch([[reedApps, 1]], 0),
    },
    {
      element: (
        <StyledListItem key="products">
          <StyledNavLink
            onClick={() => handleClick('Product Page')}
            to={Routes.products}
            activeStyle={activeLinkStyle}
            isActive={(match, location) => (
              matchPath(location.pathname, {
                path: [Routes.products, Routes.productDetail()],
                exact: true,
              })
            )}
          >
            <div>{PAGE_NAMES.products || t('page.products', 'Products')}</div>
          </StyledNavLink>
        </StyledListItem>
      ),
      show: canSee('products'),
      sort: envSwitch([[reedApps, 0]], 1),
    },
  ]
    .filter((it) => it.show)
    .sort((a, b) => a.sort - b.sort)
    .map((it) => it.element);


  return (
    <>
      {canSee('lobby') && <BookmarksModal open={bookmarksModalOpen} onClose={closeBookmarks} />}
      <StyledDrawer
        anchor="left"
        open={open}
        onClose={onClose}
      >
        {!hideUserSession && userSession?.firstName && (
          <UserContainer>
            <UserAvatar name={userSession.firstName} />
            <UserName>
              {t('greeting', 'Hi, %{name}', { name: userSession.firstName })}
            </UserName>
          </UserContainer>
        )}
        <StyledListContainer hideUserSession={hideUserSession}>
          <List>
            {
              canSee('lobby') && (
                <StyledListItem>
                  <StyledNavLink
                    onClick={() => handleClick('Lobby')}
                    to={Routes.lobby}
                    activeStyle={activeLinkStyle}
                    isActive={(match, location) => (
                      matchPath(location.pathname, {
                        path: [Routes.lobby, Routes.root],
                        exact: true,
                      })
                    )}
                  >
                    <div>{lobbyName}</div>
                    {lobbyTranslatedName && <TranslatedName>{lobbyTranslatedName}</TranslatedName>}
                  </StyledNavLink>
                </StyledListItem>
              )
            }
            {
              canSee('hall') && (
                <StyledListItem>
                  {hallLink}
                </StyledListItem>
              )
            }
            {
              canSee('webinar') && (
                <StyledListItem>
                  {webinarLink}
                </StyledListItem>
              )
            }
            {
              canSee('news') && Boolean(newsLink) && (
                <StyledListItem>
                  {newsLink}
                </StyledListItem>
              )
            }
            {
              canSee('participant') && (
                <StyledListItem>
                  <StyledNavLink
                    onClick={() => handleClick('Participant')}
                    to={Routes.participant}
                    activeStyle={activeLinkStyle}
                    isActive={(match, location) => (
                      matchPath(location.pathname, {
                        path: [Routes.participant],
                        exact: true,
                      })
                    )}
                  >
                    <div>{participantName}</div>
                    {lobbyTranslatedName && <TranslatedName>{participantTranslatedName}</TranslatedName>}
                  </StyledNavLink>
                </StyledListItem>
              )
            }
            {
              canSee('publicChatroom') && (
                <StyledListItem>
                  <StyledPublicChatBtn onClick={() => handleClick('Networking Lounge')} isRongCloudInitialized={isRongCloudInitialized}>
                    Networking Lounge
                  </StyledPublicChatBtn>
                </StyledListItem>
              )
            }
            {sortedLinks}
            <NavBarMiddleSpacer />
            {canSee('lobby') && (
              <BookmarksContext.Consumer>
                {({ bookmarkedAttachments }) => (
                  (ENABLED_FEATURES.bookmarks && totalBookmarks !== 0) && (
                    <StyledListItem onClick={openBookmarks}>
                      <BookmarkIcon />
                      <StyledNavButtonText>
                        Bookmark
                      </StyledNavButtonText>
                    </StyledListItem>
                  )
                )}
              </BookmarksContext.Consumer>
            )}
            <StyledListItem onClick={onHelpBtnClick}>
              <HelpOutlineIcon />
              <StyledNavButtonText>
                {t('help', 'Help')}
              </StyledNavButtonText>
            </StyledListItem>
          </List>
        </StyledListContainer>
        {
          !hideUserSession && userSession?.firstName && (
            <LogoutButtonContainer>
              <StyledLogoutBtn onClick={onLogoutClick}>
                <IconLogout color="#484848" />
                <LogoutText>
                  {t('logout', 'Logout')}
                </LogoutText>
              </StyledLogoutBtn>
            </LogoutButtonContainer>
          )
        }
      </StyledDrawer>
    </>
  );
};

DrawerView.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

DrawerView.defaultProps = {
  open: false,
  onClose: () => { },
};

export default DrawerView;
