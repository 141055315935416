import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CloudIcon from 'assets/cloudIcon.svg';
import { RoundedButton, ButtonIcon } from 'components/input/RoundedButton';

const DownloadButton = styled(RoundedButton)`
  background: white;
  padding: 0 24px;
  vertical-align: middle;
  display: inline-flex;
  border: 2px solid #DBDEE2;
  
  &:disabled {
    opacity: 0.4 !important;
  }
`;


const DownloadAllText = styled.span`
  margin-right: ${({ disabled }) => (disabled ? '8px' : '0')};
  color: #484848;
`;

const DownloadButtonContentContainer = styled.div`
  display: inline-flex;
`;

const DownloadAllButton = ({ status, onClick }) => (
  <DownloadButton disabled={!!status} onClick={onClick}>
    <DownloadButtonContentContainer>
      <ButtonIcon>
        <img src={CloudIcon} alt="Download" />
      </ButtonIcon>
      <DownloadAllText disabled={status}>Download</DownloadAllText>
    </DownloadButtonContentContainer>
  </DownloadButton>
);

DownloadAllButton.propTypes = {
  status: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default DownloadAllButton;
