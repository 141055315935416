import React, { useContext, useEffect, useRef } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector, batch, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { UserSessionContext } from 'components/UserSession';
import { VIDEO_CALL_URL } from 'appenv';
import { setCallInfo } from 'models/rtcCallingInfo';
import { setCallStatus } from 'models/rtcCallingStatus';

import ChatContext from './ChatContext';
import RtcChatDesktop from './RtcChatDesktop';
import RtcChatMobile from './RtcChatMobile';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const audio = require('assets/ring.mp3');

const SnackbarContainer = styled.div`
  & > div {
    position: absolute;
    top: 40%;
    z-index: 2000;

    .MuiSnackbarContent-root {
      min-width: fit-content;
    }
  }
`;

interface VideoCallMethodType {
  accept(callInvitationId: string, callInvitationType: number, roomToken: string): void;
  hangup(callInvitationId: string, callInvitationType: number, roomToken: string): void;
}

const RtcChatBackdrop = ({
  accept,
  hangup,
}: VideoCallMethodType) => {
  const rtcCallingInfo = useSelector((state: any) => state.rtcCallingInfo);
  const rtcCallingStatus: string = useSelector((state: any) => state.rtcCallingStatus);
  const { rtcAlert, setRtcAlert }: { rtcAlert: string; setRtcAlert(value: string): void } = useContext(ChatContext);

  const audioRef = useRef(new Audio(audio));

  const isDesktop = useMediaQuery('(min-width: 960px)');
  const { userSession } = useContext(UserSessionContext);
  const { rongcloudUserId } = (userSession || {});
  const videoCallUrl = `${VIDEO_CALL_URL}?roomToken=${rtcCallingInfo.roomToken}&participantId=${rongcloudUserId}`;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!['available', 'inviter_connecting', 'invitee_connecting'].includes(rtcCallingStatus)) {
      audioRef.current.loop = true;
      audioRef.current.load();
      const promise = audioRef.current.play();
      if (promise) {
        promise.then().catch((error) => {
          console.log(error);
        });
      }
    } else if (!audioRef.current.paused) {
      audioRef.current.loop = false;
      audioRef.current.pause();
    }
  }, [audioRef, rtcCallingStatus]);

  const decline = () => hangup(rtcCallingInfo.inviter, rtcCallingInfo.conversationType, rtcCallingInfo.roomToken);

  const acceptInvitation = () => accept(rtcCallingInfo.inviter, rtcCallingInfo.conversationType, rtcCallingInfo.roomToken);

  const cancel = () => hangup(rtcCallingInfo.chatId, rtcCallingInfo.conversationType, rtcCallingInfo.roomToken);

  const close = () => {
    batch(() => {
      dispatch(setCallStatus('available'));
      dispatch(setCallInfo({}));
    });
  };

  return (
    <>
      {
        isDesktop ? (
          <RtcChatDesktop
            rtcCallingInfo={rtcCallingInfo}
            rtcCallingStatus={rtcCallingStatus}
            decline={decline}
            accept={acceptInvitation}
            cancel={cancel}
            videoCallUrl={videoCallUrl}
            close={close}
          />
        ) : (
          <RtcChatMobile
            rtcCallingInfo={rtcCallingInfo}
            rtcCallingStatus={rtcCallingStatus}
            decline={decline}
            accept={acceptInvitation}
            cancel={cancel}
            videoCallUrl={videoCallUrl}
            close={close}
          />
        )
      }
      <SnackbarContainer>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={rtcAlert.length !== 0}
          autoHideDuration={1500}
          onClose={() => setRtcAlert('')}
          message={rtcAlert}
        />
      </SnackbarContainer>
    </>
  );
};

export default RtcChatBackdrop;
