import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import backIcon from 'assets/backIcon.svg';
import closeBtnGrayIcon from 'assets/closeBtnGray.svg';
import groupMemberIcon from 'assets/chatwindow/memberIcon.svg';
import VideoCallIcon from 'assets/video_call.svg';

import GroupMemberList from './GroupMemberList';
import ChatContext from './ChatContext';

const StyledModal = withStyles(() => ({
  modal: {
    '& [tabindex="-1"]': {
      outline: 0,
    },
  },
}))(Modal);

const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  margin-top: 0;
  background-color: #FFF;
  overflow: scroll;
`;

const TitleContainer = styled.div`
  font-family: Lato;
  background-color: #FFF;
  color: #484848;
  margin: 0;
  height: 44px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const TitleText = styled.h3`
  font-family: Lato;
  margin-left: unset;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50%;
  font-size: 14px;
`;

const CloseBtn = styled.div`
  position: absolute;
  height: 16px;
  width: 16px;
  left: ${({ isRenderBackBtn }) => (isRenderBackBtn ? '50px' : '16px')};
  top: 14px;
  cursor: pointer;
`;

const CloseBtnImg = styled.img`
  height: 16px;
  width: 16px;
`;

const StyledBackBtn = styled.div`
  position: absolute;
  height: 16px;
  width: 16px;
  left: 16px;
  top: 14px;
  cursor: pointer;
`;

const StyledBackImg = styled.img`
  height: 16px;
  width: 16px;
`;

const GroupMemberImg = styled.img`
  height: 24px;
  width: 24px;
`;

const GroupMemberBtn = styled.div`
  position: absolute;
  right: 0;
  top: 8px;
  margin-right: 16px;
  cursor: pointer;
`;

const CallBtnContainer = styled.div`
  margin-left: auto;
  display: flex;
  position: absolute;
  right: 0;
`;

const CallBtn = styled.div`
  margin-right: 16px;

  img {
    height: 24px;
    width: 24px;
  }
`;

const MobileChatWindow = ({
  joiningChat,
  isChatWindowShow,
  needChatRoomList,
  handleBackToPreviousChatroom,
  chatRoomTitle,
  ChatWindowComponent,
  ChatConversationListComponent,
  handleAvatarOnclick,
  showGroupMemberList,
  setShowGroupMemberList,
}) => {
  const { targetId = '', type } = useSelector((state) => state.chatTarget);
  const { closeChatWindow, needsRtcCall, call } = useContext(ChatContext);

  const isRenderBackBtn = !isEmpty(targetId) && needChatRoomList;

  const handleGroupMemberBtnOnClick = () => {
    setShowGroupMemberList(!showGroupMemberList);
  };

  const isGroup = type === 'group';

  const renderChatComponent = () => (
    !isEmpty(targetId) ? ChatWindowComponent : null
  );

  return (
    <StyledModal
      open={isChatWindowShow}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ModalContainer>
        <TitleContainer>
          {
            isRenderBackBtn && (
              <StyledBackBtn
                onClick={handleBackToPreviousChatroom}
              >
                <StyledBackImg src={backIcon} alt="back" />
              </StyledBackBtn>
            )
          }
          <CloseBtn isRenderBackBtn={isRenderBackBtn} onClick={closeChatWindow}>
            <CloseBtnImg src={closeBtnGrayIcon} alt="close" />
          </CloseBtn>
          <TitleText>
            {chatRoomTitle}
          </TitleText>
          {
            isGroup && !isEmpty(targetId) && (
              <GroupMemberBtn onClick={handleGroupMemberBtnOnClick}>
                <GroupMemberImg
                  src={groupMemberIcon}
                  alt="group-member-list"
                />
              </GroupMemberBtn>
            )
          }
          {
            needsRtcCall && !isGroup && !isEmpty(targetId) && (
              <CallBtnContainer>
                <CallBtn onClick={() => call(chatRoomTitle, targetId, [targetId], window.RongIMLib.ConversationType.PRIVATE)}>
                  <img src={VideoCallIcon} alt="Video Call" />
                </CallBtn>
              </CallBtnContainer>
            )
          }
        </TitleContainer>
        {renderChatComponent()}
        {isEmpty(targetId) && ChatConversationListComponent}
        {
          showGroupMemberList && (
            <GroupMemberList
              joiningChat={joiningChat}
              handleAvatarOnclick={handleAvatarOnclick}
              isMobile
            />
          )
        }
      </ModalContainer>
    </StyledModal>
  );
};

MobileChatWindow.propTypes = {
  joiningChat: PropTypes.bool.isRequired,
  isChatWindowShow: PropTypes.bool.isRequired,
  needChatRoomList: PropTypes.bool.isRequired,
  handleBackToPreviousChatroom: PropTypes.func.isRequired,
  chatRoomTitle: PropTypes.string.isRequired,
  ChatWindowComponent: PropTypes.node.isRequired,
  ChatConversationListComponent: PropTypes.node.isRequired,
  handleAvatarOnclick: PropTypes.func.isRequired,
  showGroupMemberList: PropTypes.bool.isRequired,
  setShowGroupMemberList: PropTypes.func.isRequired,
};

export default MobileChatWindow;
