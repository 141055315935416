import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import {
  RONGCLOUD_CHATROOM_PREFIX, PAGE_NAMES, ENABLED_FEATURES, ENV,
} from 'appenv';
import { normalize, schema } from 'normalizr';
import attendeesByIds from 'network/attendeesByIds';
import { selectLocale } from 'models/localization';
import { selectBoothEntities } from 'models/cms/booths';

const getBoothName = ({ id, getState }) => {
  const locale = selectLocale(getState());
  const fallbackLocale = 'en';

  if (ENABLED_FEATURES.xtraCMS) {
    const booths = selectBoothEntities(getState());
    const result = id.split(`${ENV}_`);
    const booth = booths[result[1]];
    return booth?.name?.[locale] || booth?.name?.[fallbackLocale];
  }

  const booths = getState().exhibitors.entities;
  const result = id.split(`${RONGCLOUD_CHATROOM_PREFIX}_booth_`);
  const booth = booths[result[1]];
  return booth?.translations?.[locale]?.name || booth?.translations?.[fallbackLocale]?.name;
};

const conversationEntity = new schema.Entity('conversations');

const conversationAdapter = createEntityAdapter();

export const {
  selectById: selectConversationById,
  selectIds: selectConversationIds,
  selectEntities: selectConversationEntities,
  selectAll: selectAllConversations,
  selectTotal: selectTotalConversations,
} = conversationAdapter.getSelectors((state) => state.conversations);

export const sortConversationsBySentTime = () => createSelector(
  selectAllConversations,
  (conversations) => conversations.filter((conversation) => (
    conversation.id !== `${RONGCLOUD_CHATROOM_PREFIX}_announcement`
  )).sort((a, b) => (a.sentTime < b.sentTime ? 1 : -1)),
);

export const addConversation = createAsyncThunk(
  'conversations/addOne',
  async (payload, { getState }) => {
    try {
      const conversations = getState().conversations.entities;
      const { targetId } = getState().chatTarget;
      const { id, type, message } = payload;
      let conversation = conversations[id];
      if (!conversation) {
        if (type === 'private') {
          const attendees = await attendeesByIds([id]);
          const attendee = attendees[0] || {};
          const customAttendeeFields = attendee.customAttendeeFields || {};
          conversation = {
            id,
            conversationType: type,
            name: attendee.name || id,
            tag: customAttendeeFields.vexpo_chat_tag,
            description: customAttendeeFields.vexpo_chat_user_description,
            unreadMessageCount: (isEmpty(message) || targetId === id) ? 0 : 1,
          };
        } else {
          conversation = {
            id,
            conversationType: type,
            unreadMessageCount: 0,
          };
        }
      }

      if (conversation && type !== 'private') {
        let name = conversation.name || id;
        if (id === `${RONGCLOUD_CHATROOM_PREFIX}_hall`) {
          name = `${PAGE_NAMES.hall} (Public Chat)`;
        } else if (id === `${RONGCLOUD_CHATROOM_PREFIX}_public_chat`) {
          name = 'Networking Lounge';
        } else if (id === `${RONGCLOUD_CHATROOM_PREFIX}_announcement`) {
          name = 'Announcement';
        } else {
          const boothName = getBoothName({ id, getState });
          if (boothName) {
            name = boothName;
          }
        }
        conversation = {
          ...conversation,
          name,
        };
      }

      if (!isEmpty(message)) {
        conversation = {
          ...conversation,
          latestMessage: message,
          sentTime: message.sentTime,
          unreadMessageCount: targetId === id ? 0 : conversation.unreadMessageCount + 1,
        };
      } else {
        conversation = {
          ...conversation,
          sentTime: conversation.sentTime || 0,
        };
      }
      const normalizedData = normalize(conversation, conversationEntity);
      return normalizedData.entities;
    } catch (error) {
      console.error(error);
    }
    return {};
  },
);

export const setConversations = createAsyncThunk(
  'conversations/setConversations',
  async ({
    list,
    ids,
  }, { getState }) => {
    try {
      const attendees = await attendeesByIds(ids);
      list.map((conversation) => {
        if (conversation.conversationType === 'private') {
          const attendee = attendees.find((each) => each.id === conversation.id);
          if (attendee) {
            const customAttendeeFields = attendee.customAttendeeFields || {};
            conversation.name = attendee.name;
            conversation.tag = customAttendeeFields.vexpo_chat_tag;
            conversation.description = customAttendeeFields.vexpo_chat_user_description;
          }
        } else if (conversation.id === `${RONGCLOUD_CHATROOM_PREFIX}_hall`) {
          conversation.name = `${PAGE_NAMES.hall} (Public Chat)`;
        } else if (conversation.id === `${RONGCLOUD_CHATROOM_PREFIX}_public_chat`) {
          conversation.name = 'Networking Lounge';
        } else if (conversation.id === `${RONGCLOUD_CHATROOM_PREFIX}_announcement`) {
          conversation.name = 'Announcement';
        } else {
          const boothName = getBoothName({ id: conversation.id, getState });
          if (boothName) {
            conversation.name = boothName;
          }
        }
        if (!conversation.name) {
          conversation.name = conversation.id;
        }
        return conversation;
      });
      const normalizedData = normalize(list, [conversationEntity]);
      return normalizedData.entities;
    } catch (error) {
      console.error(error);
    }
    return {};
  },
);

export const ConversationSlice = createSlice({
  name: 'conversations',
  initialState: conversationAdapter.getInitialState(),
  reducers: {
    updateConversation: conversationAdapter.updateOne,
  },
  extraReducers: {
    [setConversations.fulfilled]: (state, action) => {
      if (action.payload.conversations) {
        conversationAdapter.upsertMany(state, action.payload.conversations);
      }
    },
    [addConversation.fulfilled]: (state, action) => {
      conversationAdapter.upsertMany(state, action.payload.conversations);
    },
  },
});

export const {
  updateConversation,
} = ConversationSlice.actions;

export default ConversationSlice.reducer;
