import React, { useState, useContext, useMemo } from 'react';
import styled from 'styled-components';
import Modal from '@material-ui/core/Modal';
import {
  APP, ENV,
  BRAND_COLOR,
  PAGE_NAMES,
} from 'appenv';
import Apps from 'apps';
import Routes from 'Routes';
import ChatContext from 'components/chat/ChatContext';
import isChatroomDisabled from 'components/chat/chat_window_711/isChatroomDisabled711';
import isChatroomDisabledByReed from 'components/chat/utils/isChatroomDisabledByReed';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectLocale } from 'models/localization';
import { Booth, DesignElement, DesignElementKind } from 'models/cms/booths';
import InsertInvitationIcon from 'assets/calendarWhite.svg';
import LivestreamIcon from 'assets/livestream.svg';
import videoCallSrc from 'assets/videoCall.svg';
import EmailIcon from 'assets/emailWhite.svg';
import chatroomIcon from 'assets/chatroomWhite.svg';
import CloseBtn from 'assets/closeBtn.svg';
import XtraBreadcrumbs from 'components/XtraBreadcrumbs';
import { BoothProfileTabsContext } from 'pages/booths/components/BoothProfileTabsContext';
import TabsId from 'pages/booths/components/TabsId';
// import Livestream from 'components/icons/Livestream';
import MobileBoothBannerSlider from './MobileSlider';

const FlexContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding-bottom: 20px;
  position: relative;
`;

const LogoContainer = styled.div`
  width: 100%;
  margin: 16px auto;
  text-align: center;
`;

const StyledIcon = styled.img`
  filter: grayscale(100%) brightness(900%);
`;

const NameText = styled.div`
  font-size: 18px;
  line-height: 18px;
  margin: 0 16px 8px;
  text-align: center;
`;

const PopupBtn = styled.div`
  position: fixed;
  bottom: 50px;
  right: 20px;
  width: 60px;
  height: 60px;
  color: #FFF;
  text-align: center;
  line-height: 76px;
  background: ${BRAND_COLOR || '#0CA58B'};
  border-radius: 60px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.24);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BubbleBtnGroup = styled.div`
  position: absolute;
  bottom: 115px;
  right: 26px;
  background-color: rgba(0, 0, 0, 0);
`;

const StyledHyperLink = styled.a`
  text-decoration: none;
  color: #282B40;

  &:visited {
    color: #282B40;
  }
`;

const BunnbleBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const BubbleBtnText = styled.div`
  font-size: 14px;
  line-height: 14px;
  color: #282B40;
  margin-right: 16px;
`;

const BubbleBtn = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 48px;
  line-height: 65px;
  color: #FFF;
  background: ${BRAND_COLOR || '#0CA58B'};
  text-align: center;
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledModal = styled(Modal)`
  background-color: rgba(255, 255, 255, 0.89);

  & > div {
    background-color: rgba(0, 0, 0, 0) !important;

    &:focus {
      outline: none;
    }
  }
`;

const OverflowBlocker = styled.div`
  width: 17px;
  background-color: #FEFEFE;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
`;

const OverflowBlockerRight = styled(OverflowBlocker)`
  right: 0;
  left: auto;
`;


const filterNullAsset = (asset: DesignElement, locale: string) => {
  switch (asset.kind) {
    case DesignElementKind.Color: return !!asset.color;
    case DesignElementKind.Image: return !!asset.image[locale]?.originalImageUrl;
    case DesignElementKind.PDF: return !!(asset.pdf[locale]?.fileUrl || asset.thumbnailImage[locale]?.originalImageUrl);
    case DesignElementKind.Video: return !!asset.video[locale]?.playbackUrl;
    case DesignElementKind.Richtext:
    default: return false;
  }
};

const MobileProfile = ({ booth }: { booth: Booth }) => {
  const { t } = useTranslation();
  const locale = useSelector(selectLocale);

  const [modalOpen, setModalOpen] = useState(false);
  const { livestreams, videoCallUrl } = booth;
  const { showChatWindow } = useContext(ChatContext);
  const bannerFiles = useMemo(() => {
    let banners = booth.designElements.filter((element) => (
      element.name.split('_')[1] === 'banner'
    ));
    const topBannerIndex = banners.findIndex((element) => (
      element.name === 'top_banner'
    ));
    const middleBannerIndex = banners.findIndex((element) => (
      element.name === 'middle_banner'
    ));
    if (booth.appliedDesignCodename === '002' && middleBannerIndex !== -1) {
      const [middleBanner] = banners.splice(middleBannerIndex, 1);
      banners = [{ link: booth.website, ...middleBanner } as any, ...banners];
    } else if (topBannerIndex !== -1) {
      const [topBanner] = banners.splice(topBannerIndex, 1);
      banners = [{ link: booth.website, ...topBanner } as any, ...banners];
    }
    return banners.filter((it) => filterNullAsset(it, locale));
  }, [booth, locale]);
  const { setActiveTabKey, tabsIndexMap } = useContext(BoothProfileTabsContext);

  const goLiveStreamTab = (index) => {
    setActiveTabKey(index || tabsIndexMap[TabsId.LiveStreams]);
    document.getElementById('booth-container')
      .scroll({
        top: 1000,
        left: 0,
        behavior: 'smooth',
      });
    setModalOpen(false);
  };

  const tabs = booth.rootContentElement?.tabs || [];
  const emailContacts = useMemo(
    () => tabs
      .flatMap((it) => it.body)
      // eslint-disable-next-line no-underscore-dangle
      .filter((it) => it.__typename === 'BoothQuestionContentElement' && it?.answer?.contentRichtextHtml?.[locale])
      .map((it) => ({
        type: 'email',
        contact: it.answer.contentRichtextHtml[locale].match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi)?.[0],
      }))
      .filter((it) => it.contact),
    [tabs, locale],
  );

  const mailToLink = useMemo(() => {
    if (emailContacts && emailContacts.length > 0) {
      const email = emailContacts.find((eachemailContact) => (eachemailContact.type === 'email'));
      if (email?.contact) {
        switch (APP) {
          case 'scmp711':
            return `mailto:${email.contact}?subject=Enquiry from Singapore Property Festival 2020`;
          case Apps.Mig0828:
            return `mailto:${email.contact}?subject=Enquiry from The 12th InfoSecurity Summit 2020&body=Your registered Email:%0D%0AYour Message:`;
          default:
            return `mailto:${email.contact}`;
        }
      }
    }
    return '';
  }, [emailContacts]);

  const openChatWindow = () => {
    window.trackingEvent('Booth', 'Group_Chat_Click', `${booth.id} - ${booth.name[locale]}`);
    window.trackingEvent('PublicChatroom_Enter', 'Booth_Networking_Widget_Click', `${booth.id} - ${booth.name[locale]}`);
    setModalOpen(false);
    showChatWindow(`${ENV}_${booth.id}`, 'group', booth.name[locale]);
  };

  const clickMeetup = () => {
    window.trackingEvent('Booth', 'Meetup_Click', `${booth.id} - ${booth.name[locale]}`);
  };

  const clickLeaveMessage = () => {
    window.trackingEvent('Booth', 'Leave a Message_Click', `${booth.id} - ${booth.name[locale]}`);
  };

  const SelectedLogoContainer = (() => (
    <>
      <LogoContainer>
        <MobileBoothBannerSlider assets={bannerFiles} />
      </LogoContainer>
      <OverflowBlocker />
      <OverflowBlockerRight />
    </>
  ))();
  return (
    <>
      <XtraBreadcrumbs
        paths={[
          { name: PAGE_NAMES.lobby || t('page.lobby', 'Lobby'), to: Routes.lobby },
          { name: PAGE_NAMES.hall || t('page.hall', 'Exhibition Hall'), to: Routes.hall },
          { name: booth.name[locale] },
        ]}
      />
      <FlexContainer>
        {SelectedLogoContainer}
        <NameText>{booth.name[locale]}</NameText>
      </FlexContainer>
      <PopupBtn onClick={() => setModalOpen(true)}>
        <StyledIcon src={chatroomIcon} alt="chatroom" />
      </PopupBtn>
      <StyledModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <BubbleBtnGroup>
          {
            videoCallUrl && (
              <StyledHyperLink href={booth.videoCallUrl} target="_blank" rel="noopener noreferrer">
                <BunnbleBtnContainer>
                  <BubbleBtnText>
                    {t('booth.video_call', 'Video Call')}
                  </BubbleBtnText>
                  <BubbleBtn>
                    <StyledIcon src={videoCallSrc} />
                  </BubbleBtn>
                </BunnbleBtnContainer>
              </StyledHyperLink>
            )
          }
          {
            livestreams && livestreams.length > 0 && (
              <BunnbleBtnContainer onClick={() => goLiveStreamTab(livestreams[0].index)}>
                <BubbleBtnText>
                  {t('booth.live_stream', 'Livestream')}
                </BubbleBtnText>
                <BubbleBtn>
                  <StyledIcon src={LivestreamIcon} />
                </BubbleBtn>
              </BunnbleBtnContainer>
            )
          }
          {
            booth.meetupEnabled && booth.appointmentBookingUrl && (
              <StyledHyperLink href={booth.appointmentBookingUrl} target="_blank" rel="noopener noreferrer">
                <BunnbleBtnContainer onClick={clickMeetup}>
                  <BubbleBtnText>
                    Meetup
                  </BubbleBtnText>
                  <BubbleBtn>
                    <StyledIcon src={InsertInvitationIcon} alt="Insert invitation" />
                  </BubbleBtn>
                </BunnbleBtnContainer>
              </StyledHyperLink>
            )
          }
          {
            mailToLink && (
              <StyledHyperLink href={mailToLink} target="_blank" rel="noopener noreferrer">
                <BunnbleBtnContainer onClick={clickLeaveMessage}>
                  <BubbleBtnText>
                    Leave a Message
                  </BubbleBtnText>
                  <BubbleBtn>
                    <StyledIcon src={EmailIcon} alt="Leave a message" />
                  </BubbleBtn>
                </BunnbleBtnContainer>
              </StyledHyperLink>
            )
          }
          {
            // 801 chat room hide icon after live day
            !['paloalto623', Apps.Jetro817, Apps.Uzabase826].includes(APP)
            && !isChatroomDisabledByReed()
            && !isChatroomDisabled()
            && (
              <BunnbleBtnContainer onClick={openChatWindow}>
                <BubbleBtnText>
                  Chat
                </BubbleBtnText>
                <BubbleBtn>
                  <StyledIcon src={chatroomIcon} alt="Chatroom" />
                </BubbleBtn>
              </BunnbleBtnContainer>
            )
          }
          <PopupBtn onClick={() => setModalOpen(false)}>
            <StyledIcon src={CloseBtn} alt="Close" />
          </PopupBtn>
        </BubbleBtnGroup>
      </StyledModal>
    </>
  );
};

export default MobileProfile;
