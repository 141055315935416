import { normalize, schema } from 'normalizr';
import { FETCH_DEFAULT_BOOKMARK_URL } from 'appenv';
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { CMSFile } from './cmsFile';

export interface DefaultBookmark {
  id: number;
  sort: number;
  removable: boolean;
  attachment: CMSFile;
}

const defaultBookmarkEntity = new schema.Entity('defaultBookmarks');

export const fetchDefaultBookmarks = createAsyncThunk(
  'defaultBookmarks/fetchAll',
  async () => {
    try {
      const response = await axios.get(FETCH_DEFAULT_BOOKMARK_URL);
      const normalized = normalize(response.data.data, [defaultBookmarkEntity]);
      return normalized.entities;
    } catch (error) {
      console.error(error);
    }
    return {};
  },
);

const defaultBookmarkAdapter = createEntityAdapter<DefaultBookmark>();
export const {
  selectEntities: selectDefaultBookmarkEntities,
  selectAll: selectAllDefaultBookmarks,
  selectById: selectDefaultBookmarkById,
} = defaultBookmarkAdapter.getSelectors((state: any) => state.defaultBookmarks);

export const defaultBookmarksSlice = createSlice({
  name: 'defaultBookmarks',
  initialState: defaultBookmarkAdapter.getInitialState({ isLoading: true }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDefaultBookmarks.pending, (state: any) => {
      state.isLoading = true;
    });
    builder.addCase(fetchDefaultBookmarks.fulfilled, (state: any, action) => {
      defaultBookmarkAdapter.upsertMany(state, action.payload.defaultBookmarks);
      state.isLoading = false;
    });
    builder.addCase(fetchDefaultBookmarks.rejected, (state: any) => {
      state.isLoading = false;
    });
  },
});

export default defaultBookmarksSlice.reducer;
