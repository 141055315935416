const bannerUtils = {
  toImage: (src: string, href = null) => ({
    src,
    href,
    type: 'image',
  }),
};

export const en = {
  entry: {
    to_exhibition_hall: 'Enter Exhibition Hall',
    how_to_use_oms: '<b>How to utilize</b><b>virtual expo?</b>',
  },
  banners: {
    left: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/ccc6a719-9d95-4810-a842-fbc863817bfc.png',
      ),
    ],
    right: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/ccc6a719-9d95-4810-a842-fbc863817bfc.png',
      ),
    ],
    middle: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/ccc6a719-9d95-4810-a842-fbc863817bfc.png',
        'https://www.lifestyle-expo-k.jp/ls_jmw_cosme_top_en/',
      ),
    ],
    mobile: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/ccc6a719-9d95-4810-a842-fbc863817bfc.png',
        'https://www.lifestyle-expo-k.jp/ls_jmw_cosme_top_en/',
      ),
    ],
  },
};

export const ja = {
  entry: {
    to_exhibition_hall: '展示会場はこちら',
    how_to_use_oms: '<b>はじめに</b><i>オンライン来場・商談サービスの使い方</i>',
  },
  banners: {
    left: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/a16cd73e-0bb1-4e9c-a908-5507897461ac.png',
      ),
    ],
    right: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/a16cd73e-0bb1-4e9c-a908-5507897461ac.png',
      ),
    ],
    middle: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/a16cd73e-0bb1-4e9c-a908-5507897461ac.png',
        'https://www.lifestyle-expo-k.jp/ls_jmw_cosme_top/',
      ),
    ],
    mobile: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/a16cd73e-0bb1-4e9c-a908-5507897461ac.png',
        'https://www.lifestyle-expo-k.jp/ls_jmw_cosme_top/',
      ),
    ],
  },
};
