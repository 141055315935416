import moment from 'moment';
import remoteTimeNow from 'libs/remote-time-now';
import { APP } from 'appenv';

// Datetime of Hiding chatroom entries
const CHATROOM_DISABLED_TIME = '2020-08-02T00:00:00+08:00';
const isChatroomDisabled = () => {
  const chatroomDisableTime = moment(CHATROOM_DISABLED_TIME);
  const now = remoteTimeNow();
  return APP === 'scmp711' && now.isAfter(chatroomDisableTime);
};

export default isChatroomDisabled;
