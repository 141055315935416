const FEATURES = {
  bookmarks: false,
  defaultBookmarks: false,
  networkCheck: false,
  cmsBanner: false,
  boothAutoSort: false,
  customPage: false,
  productPage: false,
  xtraCMS: false,
  boothPreview: false,
};

export default FEATURES;
