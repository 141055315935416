import {
  createEntityAdapter,
  createSlice,
  Action,
  PayloadAction,
} from '@reduxjs/toolkit';
import { Bookmark } from 'models/types/bookmarks';

enum BOOKMARK_ACTION_TYPE {
  ADD_BOOKMARK = 'ADD_BOOKMARK',
  REMOVE_BOOKMARK = 'REMOVE_BOOKMARK',
  CLEAR_BOOKMARKS = 'CLEAR_BOOKMARKS',
}

interface BookmarkAction extends Action {
  type: BOOKMARK_ACTION_TYPE;
  payload?: Bookmark | string;
}

const bookmarksAdapter = createEntityAdapter<Bookmark>();

export const {
  selectAll: selectAllBookmarks,
  selectById: selectBookmarkById,
  selectTotal: selectTotalBookmarkCount,
} = bookmarksAdapter.getSelectors((state: any) => state.bookmarks);

export const addBookmark = (bookmark: Bookmark): BookmarkAction => ({
  type: BOOKMARK_ACTION_TYPE.ADD_BOOKMARK,
  payload: bookmark,
});

export const removeBookmark = (bookmarkId: string): BookmarkAction => ({
  type: BOOKMARK_ACTION_TYPE.REMOVE_BOOKMARK,
  payload: bookmarkId,
});

export const clearBookmarks = (): BookmarkAction => ({
  type: BOOKMARK_ACTION_TYPE.CLEAR_BOOKMARKS,
});

const bookmarksSlice = createSlice({
  name: 'bookmarks',
  initialState: bookmarksAdapter.getInitialState({}),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(BOOKMARK_ACTION_TYPE.ADD_BOOKMARK, (state, action: any) => {
      bookmarksAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(BOOKMARK_ACTION_TYPE.REMOVE_BOOKMARK, (state, action: any) => {
      bookmarksAdapter.removeOne(state, action.payload);
    });
    builder.addCase(BOOKMARK_ACTION_TYPE.CLEAR_BOOKMARKS, (state, action: any) => {
      bookmarksAdapter.removeAll(state);
    });
  },
});

export default bookmarksSlice.reducer;
